// DO NOT EDIT! 
// This doc was auto generated from ./scripts/build-page-imports.js 
// Wed Sep 11 2024 20:32:16 GMT+0000 (Coordinated Universal Time)
      
// Import primary markdown tools
import {ExternalMarkdownWrapper, InternalMarkdownWrapper} from '../components/rawMarkdownWrapper';
      
// internal markdown docs
import A11yStatementPage from './markdown/a11yStatement.md'; 
import AuroSupportPage from './markdown/auroSupport.md'; 
import CodetestPage from './markdown/codetest.md'; 
import HomePage from './markdown/home.md'; 
import NodeSupportPage from './markdown/nodeSupport.md'; 
import OfficehoursPage from './markdown/officehours.md'; 
import PhilosophyPage from './markdown/philosophy.md'; 
import VoiceTonePage from './markdown/voiceTone.md'; 
import WipPage from './markdown/wip.md'; 

      
// internal markdown docs
export class A11yStatement extends InternalMarkdownWrapper {
  readme = A11yStatementPage
}

export class AuroSupport extends InternalMarkdownWrapper {
  readme = AuroSupportPage
}

export class Codetest extends InternalMarkdownWrapper {
  readme = CodetestPage
}

export class Home extends InternalMarkdownWrapper {
  readme = HomePage
}

export class NodeSupport extends InternalMarkdownWrapper {
  readme = NodeSupportPage
}

export class Officehours extends InternalMarkdownWrapper {
  readme = OfficehoursPage
}

export class Philosophy extends InternalMarkdownWrapper {
  readme = PhilosophyPage
}

export class VoiceTone extends InternalMarkdownWrapper {
  readme = VoiceTonePage
}

export class Wip extends InternalMarkdownWrapper {
  readme = WipPage
}



      

// @aurodesignsystem/wc-generator
export class BrowserSupport extends ExternalMarkdownWrapper {
  module = "@aurodesignsystem/wc-generator"
  githubURL = "https://github.com/AlaskaAirlines/wc-generator/"
  codeURL = "https://raw.githubusercontent.com/AlaskaAirlines/WC-Generator"
  readme = "docs/browserSupport.md"
}
export class Slots extends ExternalMarkdownWrapper {
  module = "@aurodesignsystem/wc-generator"
  githubURL = "https://github.com/AlaskaAirlines/wc-generator/"
  codeURL = "https://raw.githubusercontent.com/AlaskaAirlines/WC-Generator"
  readme = "docs/slots.md"
}
export class Tests extends ExternalMarkdownWrapper {
  module = "@aurodesignsystem/wc-generator"
  githubURL = "https://github.com/AlaskaAirlines/wc-generator/"
  codeURL = "https://raw.githubusercontent.com/AlaskaAirlines/WC-Generator"
  readme = "docs/test-runner.md"
}
export class Compliance extends ExternalMarkdownWrapper {
  module = "@aurodesignsystem/wc-generator"
  githubURL = "https://github.com/AlaskaAirlines/wc-generator/"
  codeURL = "https://raw.githubusercontent.com/AlaskaAirlines/WC-Generator"
  readme = "docs/compliance.md"
}

// @alaskaairux/webcorestylesheets
export class CustomProperties extends ExternalMarkdownWrapper {
  module = "@alaskaairux/webcorestylesheets"
  githubURL = "https://github.com/AlaskaAirlines/WebCoreStyleSheets/"
  codeURL = "https://raw.githubusercontent.com/AlaskaAirlines/WebCoreStyleSheets"
  readme = "documents/customProperties.md"
}
export class ShadyCSS extends ExternalMarkdownWrapper {
  module = "@alaskaairux/webcorestylesheets"
  githubURL = "https://github.com/AlaskaAirlines/WebCoreStyleSheets/"
  codeURL = "https://raw.githubusercontent.com/AlaskaAirlines/WebCoreStyleSheets"
  readme = "documents/shadyCSS.md"
}

// repo readme files
export class WCGenerator extends ExternalMarkdownWrapper {
  module = "@aurodesignsystem/wc-generator"
  githubURL = "https://github.com/AlaskaAirlines/wc-generator"
  codeURL = "https://github.com/AlaskaAirlines/WC-Generator/tree/master/template"
  readme = "README.md"
}
export class AngularDemoDocs extends ExternalMarkdownWrapper {
  githubURL = "https://github.com/AlaskaAirlines/OrionAngularDemo"
  codeURL = "https://github.com/AlaskaAirlines/OrionAngularDemo/tree/master/src"
  readme = "README.md"
}
export class JavascriptDemoDocs extends ExternalMarkdownWrapper {
  githubURL = "https://github.com/AlaskaAirlines/AuroJavascriptDemo"
  codeURL = "https://github.com/AlaskaAirlines/AuroJavascriptDemo/tree/master/src"
  readme = "README.md"
}
export class SvelteDemoDocs extends ExternalMarkdownWrapper {
  githubURL = "https://github.com/AlaskaAirlines/AuroSvelteDemo"
  codeURL = "https://github.com/AlaskaAirlines/AuroSvelteDemo/tree/master/src"
  readme = "README.md"
}
export class ReactDemoDocs extends ExternalMarkdownWrapper {
  githubURL = "https://github.com/AlaskaAirlines/AuroReactDemo"
  codeURL = "https://github.com/AlaskaAirlines/AuroReactDemo/tree/master/src"
  readme = "README.md"
}
    